import * as React from 'react'
import { useRecoilValue } from 'recoil'

import { Wrapper, Title, Paragraph, Container, ContainerButton } from './careers.style'
import { graphql } from 'gatsby'

import { isMobileState } from '@/recoil/layout'

import Layout from '@/layouts/Default'

import Hero from '@/components/Hero'
import BlockTextImages from '@/components/Block/TextImages'
import Benefits from '@/components/Benefits'
import Faq from '@/components/Faq'
import Button from '@/components/Button'
import StoreLocator from '@/components/StoreLocator'
import { Helmet } from 'react-helmet'

import { useMountEffect } from '../hook'

// import BenefitPopup from '@/components/Popup/BenefitPopup'

const CareerPage = ({ data, pageContext: { pagePath, marketCode: pageMarketCode } }) => {
  let widgetLinks = []

  const isMobile = useRecoilValue(isMobileState)

  const meta = {
    title: data.drupal.nodeById?.fieldCareersMetaTags?.entity?.fieldMetaTitle,
    description: data.drupal.nodeById?.fieldCareersMetaTags?.entity?.fieldMetaDescription,
    bypassAgeGate: data.drupal.nodeById?.fieldCareersMetaTags?.entity?.fieldMetaTagsBypassAgeGate,
    slug: data.drupal.nodeById?.fieldCareersMetaTags?.entity?.fieldMetaSlugUrl,
    image: data.drupal.nodeById?.fieldCareersMetaTags?.entity?.fieldMetaOpenGraphImage?.url
  }
  const heroData = data.drupal.nodeById.fieldCareersHeroBlock?.entity
  const introData = data.drupal.nodeById.fieldCareersIntroduction?.entity
  const benefitsData = data.drupal.nodeById.fieldCareersBenefits?.entity
  const benefitsItems = benefitsData?.fieldCareersBenefits.map((item) => ({
    icon: item.entity.fieldWineClubBenefitImage?.url,
    alt: item.entity.fieldWineClubBenefitImage?.alt,
    text: item.entity.fieldWineClubBenefitLabel,
    content: item.entity.fieldStandardDescription
  }))

  const faqData = data.drupal.nodeById.fieldCareersFaq?.entity
  const faqItemsData = faqData.fieldFaqListItem.map((item) => ({
    title: item.entity.fieldFaqItemQuestion,
    text: item.entity.fieldFaqItemAnswer
  }))
  useMountEffect(() => {
    if (window.careerScript) {
      window.careerScript()
      setTimeout(() => {
        if (typeof window !== 'undefined' && window.document && document.querySelectorAll('.whr-title a')) {
          widgetLinks = document.querySelectorAll('.whr-title a')
          widgetLinks.forEach(link => {
            link.setAttribute('target', '_blank')
          })
        }
      }, 5000)
    }
  })

  return (
    <Layout plainHeader path={ pagePath } pageType='Career' pageMarketCode={ pageMarketCode }>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={ meta.description } />
        <meta property='og:title' content={ meta.title } />
        <meta property='og:description' content={ meta.description } />
        <meta property='og:url' content={ `${process.env.URL || process.env.GATSBY_DRUPAL_URL}${pagePath}` } />
        <meta property='og:site_name' content='Cloudy Bay' />
        <meta property='og:type' content='article' />
        {meta.image && <meta property='og:image' content={ meta.image } /> }
      </Helmet>
      <Wrapper>
        <Hero
          id='textcercle1'
          subtitle={ heroData?.fieldHeroSubtitle }
          title={ heroData?.fieldHeroTitle }
          text={ heroData?.fieldHeroDescription }
          image={ isMobile ? heroData?.fieldBackgroundMobile?.url : heroData?.fieldBackgroundDesktop?.url }
          actionLabel={ heroData?.fieldHeroCta.title }
        />
        <BlockTextImages
          subtitle={ introData?.fieldCareersIntroduction.entity.fieldStandardSubtitle }
          title={ introData?.fieldCareersIntroduction.entity.fieldStandardTitle }
          text={ introData?.fieldCareersIntroduction.entity.fieldStandardDescription }
          actions={ [
            <Button key={ 1 } to='#jobs'>{introData?.fieldCareersIntroduction.entity.fieldStandardCta?.title}</Button>
          ] }
          display='fullwidth'
          images={ [
            isMobile ? introData?.fieldCareersLeftImage?.entity?.fieldSimpleImageMobile?.url : introData?.fieldCareersLeftImage?.entity?.fieldSimpleImageDesktop?.url,
            isMobile ? introData?.fieldCareerRightImage.entity.fieldSimpleImageMobile?.url : introData?.fieldCareerRightImage.entity.fieldSimpleImageDesktop?.url
          ] }
        />
        <Benefits
          subtitle={ benefitsData?.fieldSubtitle }
          title={ benefitsData?.fieldTitle }
          text={ benefitsData?.fieldDescription }
          items={ benefitsItems }
          popin
          className='careers-popup'
        />
        <Container id='jobs'>
          <Title>{data.drupal.nodeById?.fieldCareersTitle}</Title>
          <Paragraph>{data.drupal.nodeById?.fieldCareersDescription}</Paragraph>
          <ContainerButton>
            <Button key={ 1 } to={ data.drupal.nodeById?.fieldCareersLink.url.path }>{ data.drupal.nodeById?.fieldCareersLink.title }</Button>
          </ContainerButton>
          {/* <div id='whr_embed_hook' /> */}
        </Container>
        <Faq
          title={ faqData.fieldFaqTitle }
          text={ faqData.fieldFaqDescription }
          items={ faqItemsData }
        />
      </Wrapper>
      <StoreLocator pageType='Career Page' />
    </Layout>
  )
}

export const query = graphql`
query CareerQuery {
  drupal {
    nodeById(id: "521") {
      title
      nid
      path {
        alias
      }
      ... on Drupal_NodeCareers {
        fieldCareersTitle
        fieldCareersDescription
        fieldCareersLink {
          url {
            path
          }
          title
        }
        nid
        fieldCareersMetaTags {
          entity {
            ... on Drupal_ParagraphMetaTags {
              id
              fieldMetaOpenGraphImage {
                url
              }
              fieldMetaSlugUrl
              fieldMetaTitle
              fieldMetaTagsBypassAgeGate
              fieldMetaDescription
            }
            ... on Drupal_ParagraphHeroBlock {
              id
              fieldHeroDescription
              fieldHeroSubtitle
            }
          }
        }
        fieldCareersHeroBlock {
          entity {
            ... on Drupal_ParagraphHeroBlock {
              id
              fieldHeroSubtitle
              fieldHeroDescription
              fieldHeroTitle
              fieldBackgroundDesktop {
                alt
                url
              }
              fieldBackgroundMobile {
                alt
                url
              }
              fieldHeroCta {
                url {
                  path
                }
                title
              }
            }
          }
        }
        fieldCareersIntroduction {
          entity {
            id
            ... on Drupal_ParagraphCareersIntroductionBlock {
              id
              fieldCareersLeftImage {
                entity {
                  id
                  ... on Drupal_ParagraphSimpleImage {
                    id
                    fieldSimpleImageMobile {
                      alt
                      url
                    }
                    fieldSimpleImageDesktop {
                      url
                      alt
                    }
                  }
                }
              }
              fieldCareerRightImage {
                entity {
                  ... on Drupal_ParagraphSimpleImage {
                    id
                    fieldSimpleImageMobile {
                      alt
                      url
                    }
                    fieldSimpleImageDesktop {
                      alt
                      url
                    }
                  }
                }
              }
              fieldCareersIntroduction {
                entity {
                  id
                  ... on Drupal_ParagraphStandardContentBlock {
                    id
                    fieldStandardTitle
                    fieldStandardSubtitle
                    fieldStandardDescription
                    fieldStandardCta {
                      uri
                      title
                    }
                    fieldStandardContentCta2Link {
                      url {
                        routed
                        path
                      }
                      title
                    }
                  }
                }
              }
            }
          }
        }
        fieldCareersBenefits {
          entity {
            ... on Drupal_ParagraphCareersBenefits {
              id
              fieldDescription
              fieldTitle
              fieldSubtitle
              fieldCareersBenefits {
                entity {
                  id
                  ... on Drupal_ParagraphWineClubBenefitItem {
                    id
                    fieldWineClubBenefitLabel
                    fieldStandardDescription
                    fieldWineClubBenefitImage {
                      alt
                      url
                    }
                  }
                }
              }
            }
          }
        }
        fieldCareersFaq {
          entity {
            ... on Drupal_ParagraphStandardFaqList {
              id
              fieldFaqTitle
              fieldFaqDescription
              fieldFaqListItem {
                entity {
                  id
                  ... on Drupal_ParagraphStandardFaqItem {
                    id
                    fieldFaqItemAnswer
                    fieldFaqItemQuestion
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
export default CareerPage
